<template>
  <div id="userActivateId">
    <activate-id-app v-if="isShow2" class="uniApp_activateId" />
    <activate-id v-if="isShow" class="pc_activateId" />
  </div>
</template>

<script>
import ActivateId from '../login/ActivateId';
import ActivateIdApp from '../uni_login/Active1-app';
export default {
  // import引入的组件需要注入到对象中才能使用",
  components: { ActivateId, ActivateIdApp },
  data() {
    // 这里存放数据",
    return {
      isShow: false,
      isShow2: false
    };
  },
  // 监听属性 类似于data概念",
  computed: {},
  // 监控data中的数据变化",
  watch: {},
  // 生命周期 - 创建之前",数据模型未加载,方法未加载,html模板未加载
  beforeCreate() {

  },

  // 生命周期 - 创建完成（可以访问当前this实例）",数据模型已加载，方法已加载,html模板已加载,html模板未渲染
  created() {
    // setInterval(this.toObtainTheWidth, 1000);
  },
  // 生命周期 - 挂载之前",html模板未渲染
  beforeMount() {
    this.toObtainTheWidth();
  },

  // 生命周期 - 挂载完成（可以访问DOM元素）",html模板已渲染
  mounted() {
  },
  // 生命周期 - 更新之前",数据模型已更新,html模板未更新
  beforeUpdate() {

  },
  // 生命周期 - 更新之后",数据模型已更新,html模板已更新
  updated() {

  },
  // 生命周期 - 销毁之前",
  beforeDestroy() {

  },
  destroyed() {

  },
  // 生命周期 - 销毁完成",
  // 如果页面有keep-alive缓存功能，这个函数会触发",
  activated() {

  },
  // 方法集合",
  methods: {
    toObtainTheWidth() {
      let nowWidth = window.innerWidth;
      if (nowWidth > 1000) {
        this.isShow = true;
        this.isShow2 = false;
      } else if (nowWidth < 1000) {
        this.isShow = false;
        this.isShow2 = true;
      }
    }
  }
};
</script>

<style scoped lang="scss">
  #userActivateId {
    width: 100%;
    height: 100%;
    display: flex;
  }

</style>
